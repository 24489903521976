import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie} from "cookies-next";
import * as Sentry from "@sentry/nextjs";
import messages from '../../../../src/messages';
import { FormattedMessage } from 'react-intl';

export default function DialogLostPassword ({productLostPasswordUUID,toggleLostPasswordMultiple,
                                      toggleLostPassword}) {

    const [loadingLostPassword, setLoadingLostPassword] = useState(false);
    const [lostpasswordShow, setLostPasswordShow] = useState(false);
    const [lostpasswordTransitionShow, setLostPasswordTransitionShow] = useState(false);

    const changeLostPasswordShow = (variable) => {

        if(variable === true){

            setLostPasswordShow(variable);
            setTimeout(function() {
                setLostPasswordTransitionShow(true);
            },1);




        } else{


            setLostPasswordTransitionShow(false);
            setTimeout(function() {
                setLostPasswordShow(variable);
            },500);



        }

    };
    const closeCompleteReset = () => {
        setLostPasswordState(1);
        changeLostPasswordShow(false);
    };

    const [lostPasswordMessage, setLostPasswordMessage] = useState("");

    const [lostPasswordState, setLostPasswordState] = useState(1);

    const sendLostPasswordLink = () => {


        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"auth/password-recovery", {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer ", // notice the Bearer before your token
            },
            body: JSON.stringify({
                email: lostPasswordEmail
            }),
        })
            .then(response => response.json())
            .then(function(data){

                if(data.message !== undefined){
                    Sentry.captureException("Dialoglostpassword lostpasswordProduct products/"+productLostPasswordUUID+": "+ data.message + " : " + getCookie("userMail"));
                }

                if(data.message === undefined){
                    // changeLostPasswordShow(false);
                    setLostPasswordMessage("Lost password link was successfully sent !");
                    setLostPasswordState(3);
                } else {

                    setLostPasswordState(2);
                    setLostPasswordMessage(data.message);
                }

            });

    };



    const [showLostPasswordDialog, setShowLostPasswordDialog] = useState(false);
    const toggleLostPasswordInside = useCallback((): void => {
        if(showLostPasswordDialog){
            changeLostPasswordShow(false);
        }else{

            changeLostPasswordShow(true);
        }


    }, []);
    useEffect(() => {

        toggleLostPassword && toggleLostPassword(toggleLostPasswordInside);
    }, [toggleLostPassword, toggleLostPasswordInside]);


    const toggleLostPasswordInsideMultiple = useCallback((): void => {
        if(showLostPasswordDialog){
            changeLostPasswordShow(false);
        }else{
            changeLostPasswordShow(true);
        }


    }, []);
    useEffect(() => {

        toggleLostPasswordMultiple && toggleLostPasswordMultiple(toggleLostPasswordInsideMultiple);
    }, [toggleLostPasswordMultiple, toggleLostPasswordInsideMultiple]);

    const closeModalOutsideClick = (e) => {

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeLostPasswordShow(false);
        }
    }
    const [lostPasswordEmail, setLostPasswordEmail] = useState("");
    const handleLostPasswordEmail = function (e: React.FormEvent<HTMLInputElement>) {
        setLostPasswordEmail(e.currentTarget.value)
    }

    const closePassword = () => {
        changeLostPasswordShow(false);
        setLostPasswordMessage("");

    }

    return (
        <>

            <ModalsWrapper
                className={`  ${lostpasswordTransitionShow ? 'showTransition' : 'hideTransition'} 
                    ${!lostpasswordShow ? 'hiddeComplete' : ''}`}
                data-closemodal={true}
                onClick={e => closeModalOutsideClick(e)}
            >
                <ModalWindow id={'registerDialog'} className={'center modal_dialog_small'}>
                    <div className={'modal-header'} style={{    height: "55px"}}>
                        <h4 style={{ marginTop: '20px' }}><FormattedMessage {...messages.modal_lost_password_title}></FormattedMessage></h4>
                    </div>

                    <div className={'modal-content shareProduct text-center '} style={{ display: 'block' }}>
                        {!lostPasswordMessage ? (
                            <>

                                <FormattedMessage {...messages.modal_lost_password_placeholder}>
                                    {(msg) => (<input
                                        className={'input-md form-control inputRounded'}
                                        style={{ width: ' 90%', margin: 'auto auto', marginBottom: '15px' }}
                                        type="email"
                                        value={lostPasswordEmail}
                                        id={'registerInputEmail'}
                                        placeholder={msg}
                                        onChange={handleLostPasswordEmail}
                                    />)}
                                </FormattedMessage>

                                {/*/!*<span style={{marginBottom :"15px"}}>by e-mail</span>*!/*/}
                                {/*<input*/}
                                {/*    className={'input-md form-control inputRounded'}*/}
                                {/*    style={{ width: ' 90%', margin: 'auto auto', marginBottom: '15px' }}*/}
                                {/*    type="email"*/}
                                {/*    value={lostPasswordEmail}*/}
                                {/*    id={'registerInputEmail'}*/}
                                {/*    placeholder={'Enter your e-mail'}*/}
                                {/*    onChange={handleLostPasswordEmail}*/}
                                {/*/>*/}

                                {/*<LoginButton type="submit" style={{marginTop: "15px"}} onClick={() => registerByEmail()} id={"registerButton"} value="Submit"><span>Continue</span></LoginButton>*/}

                                <GlobalButtonFinal className={'btn-circle borderBlue'} onClick={() => closePassword()}>
                                    <FormattedMessage {...messages.modal_lost_password_cancel}></FormattedMessage>
                                </GlobalButtonFinal>
                                <GlobalButtonFinal id={'confirmRegister'} className={'btn-circle backgroundBlue'} style={{ marginLeft: '15px' }} onClick={() => sendLostPasswordLink()}>
                                    <FormattedMessage {...messages.modal_lost_password_continue}></FormattedMessage>
                                </GlobalButtonFinal>
                            </>
                        ) : (
                            <>
                                <span style={{       marginBottom: "20px"}} id={'registerResult'}>{lostPasswordMessage}</span>

                                <GlobalButtonFinal id={'confirmRegResult'} className={'btn-circle backgroundBlue'} style={{ marginLeft: '15px' }} onClick={() => closePassword()}>
                                    Ok
                                </GlobalButtonFinal>
                            </>
                        )}
                    </div>
                </ModalWindow>
            </ModalsWrapper>


        </>
    )
}